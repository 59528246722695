var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-page',{attrs:{"style-fn":_vm.styleFn}},[_c('ApolloQuery',{staticClass:"fit",attrs:{"id":"admin-table","fetch-policy":"no-cache","query":gql => gql`
      query getModelDocs($page: Int, $condition: JSON, $modelName: String!) {
        getModelDocs(page: $page, condition: $condition, modelName: $modelName) {
          docs
          ${_vm.PaginationFields}
        }
      }
    `,"variables":{ page: _vm.page, condition: _vm.searchCondition, modelName: _vm.modelName }},on:{"result":_vm.onQueryResult},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data, error }, query }){return [(!data)?_c('CircleLoading',{staticClass:"absolute absolute-center",attrs:{"size":"5rem"}}):[_c('q-table',{staticClass:"text-font-roboto full-width overflow-scroll no-shadow",style:(`height: ${_vm.pageHeight}px;`),attrs:{"virtual-scroll-slice-size":10,"virtual-scroll-slice-ratio-after":0.5,"virtual-scroll-slice-ratio-before":0.5,"virtual-scroll":"","square":"","virtual-scroll-item-size":71,"title-class":"text-weight-medium text-font-montserrat","title":_vm.title,"row-key":"_id","loading":_vm.loading,"columns":_vm.columns,"selection":"multiple","selected":_vm.selected,"data":_vm.docs,"pagination":{ rowsPerPage: _vm.rowsPerPage }},on:{"update:selected":function($event){_vm.selected=$event}},scopedSlots:_vm._u([{key:"body-cell",fn:function(props){return [_c('q-td',{attrs:{"props":props}},[(
                  props.col.type === 'string' || props.col.type === 'number'
                )?[(props.col.enum)?[_c('q-select',{attrs:{"option-value":props.col.optionValue,"option-label":props.col.optionLabel,"map-options":!!props.col.optionLabel,"popup-content-class":"admin-table-select","options":props.col.enum,"bg-color":"white","outlined":"","rounded":"","emit-value":""},on:{"input":value => {
                        _vm.updateMutate({
                          variables: {
                            modelName: _vm.modelName,
                            _id: props.row._id,
                            updatedFields: value
                              ? { [props.col.field]: value }
                              : {},
                            unsetFields: !value
                              ? { [props.col.field]: 1 }
                              : {}
                          }
                        });
                      }},model:{value:(props.row[props.col.field]),callback:function ($$v) {_vm.$set(props.row, props.col.field, $$v)},expression:"props.row[props.col.field]"}})]:[_vm._t('body-cell-' + props.col.name,function(){return [_vm._v(" "+_vm._s(props.value)+" ")]},null,{ ...props }),(props.col.editable)?_c('q-popup-edit',{attrs:{"auto-save":true},on:{"save":value =>
                        _vm.updateMutate({
                          variables: {
                            modelName: _vm.modelName,
                            _id: props.row._id,
                            updatedFields: { [props.col.field]: value }
                          }
                        })},scopedSlots:_vm._u([{key:"default",fn:function({ emitValue }){return [_c('q-input',{attrs:{"dense":"","counter":"","autofocus":"","value":props.row[props.col.field]},on:{"change":e => {
                            emitValue(e.target.value);
                          }}})]}}],null,true),model:{value:(props.row[props.col.field]),callback:function ($$v) {_vm.$set(props.row, props.col.field, $$v)},expression:"props.row[props.col.field]"}}):_vm._e()]]:(props.col.type === 'object')?[_vm._v(" "+_vm._s(JSON.stringify(props.value))+" "),(props.col.editable)?_c('q-popup-edit',{attrs:{"auto-save":true},on:{"save":value =>
                      _vm.updateMutate({
                        variables: {
                          modelName: _vm.modelName,
                          _id: props.row._id,
                          updatedFields: { [props.col.field]: value }
                        }
                      })},scopedSlots:_vm._u([{key:"default",fn:function({ emitValue }){return [_c('q-input',{attrs:{"dense":"","counter":"","autofocus":"","value":JSON.stringify(props.row[props.col.field])},on:{"change":e => {
                          emitValue(JSON.parse(e.target.value));
                        }}})]}}],null,true),model:{value:(props.row[props.col.field]),callback:function ($$v) {_vm.$set(props.row, props.col.field, $$v)},expression:"props.row[props.col.field]"}}):_vm._e()]:(props.col.type === 'array')?[_c('q-select',{attrs:{"options":props.col.enum || [],"disable":!props.col.editable,"option-label":props.col.optionLabel,"map-options":!!props.col.optionLabel,"emit-value":"","multiple":"","use-chips":"","label":props.col.label,"option-value":props.col.optionValue},on:{"input":value =>
                      _vm.updateMutate({
                        variables: {
                          modelName: _vm.modelName,
                          _id: props.row._id,
                          updatedFields: { [props.col.field]: value }
                        }
                      })},model:{value:(props.row[props.col.field]),callback:function ($$v) {_vm.$set(props.row, props.col.field, $$v)},expression:"props.row[props.col.field]"}})]:(props.col.type === 'key-value')?[_vm._l((props.col.format
                    ? props.col.format(props.row[props.col.field], props.row)
                    : props.row[props.col.field]),function(value,key){return [_c('q-chip',[_vm._v(" "+_vm._s(key)+": "+_vm._s(value)+" ")]),_c('br')]})]:(props.col.type === 'boolean')?[_c('q-toggle',{attrs:{"disable":!props.col.editable},on:{"input":value =>
                      _vm.updateMutate({
                        variables: {
                          modelName: _vm.modelName,
                          _id: props.row._id,
                          updatedFields: { [props.col.field]: value }
                        }
                      })},model:{value:(props.row[props.col.field]),callback:function ($$v) {_vm.$set(props.row, props.col.field, $$v)},expression:"props.row[props.col.field]"}})]:_vm._e()],2)]}},{key:"pagination",fn:function(scope){return [_c('q-pagination',{attrs:{"color":"grey-8","max":_vm.pagesNumber,"size":"md","input":true},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]}},{key:"top-right",fn:function(){return [(_vm.$q.screen.gt.sm)?_vm._t("top-right-bots",null,null,{ query, search: _vm.search }):_vm._e(),_c('div',{staticClass:"grid-row flex items-center q-mt-sm full-width",style:(_vm.$q.screen.gt.sm ? 'flex-wrap: nowrap;' : undefined)},[(_vm.$q.screen.gt.sm)?_c('q-input',{staticClass:"q-pr-sm",style:(_vm.$q.screen.gt.sm
                    ? 'min-width: 600px'
                    : 'max-width: 500px; min-width: 500px'),attrs:{"rounded":"","color":"primary","outlined":"","dense":"","label":"Поиск"},model:{value:(_vm.searchConditionText),callback:function ($$v) {_vm.searchConditionText=$$v},expression:"searchConditionText"}}):_vm._e(),(_vm.$q.screen.lt.md)?_vm._t("top-right-bots",null,null,{ query, search: _vm.search }):_vm._e(),_vm._t("top-right",null,null,{ query, search: _vm.search }),_vm._t("top-left",null,null,{ query, search: _vm.search }),_c('q-btn',{staticClass:"text-no-wrap",attrs:{"label":"Снять все","color":"primary","rounded":"","push":"","size":"md","disable":!_vm.selected.length},on:{"click":_vm.deselectAll}}),_c('ApolloMutation',{attrs:{"mutation":gql => gql`
                    mutation deleteModelDocs(
                      $_ids: [ID!]!
                      $modelName: String!
                    ) {
                      deleteModelDocs(_ids: $_ids, modelName: $modelName)
                    }
                  `,"variables":{ _ids: _vm.selected.map(v => v._id), modelName: _vm.modelName }},on:{"done":() => {
                    _vm.selected = [];
                    query.refetch();
                  },"error":error =>
                    _vm.$q.notify({
                      message: error.message,
                      multiLine: true,
                      type: 'negative'
                    })},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [_c('q-btn',{attrs:{"label":"Удалить","color":"negative","rounded":"","push":"","size":"md","loading":loading,"disable":!_vm.selected.length},on:{"click":function($event){_vm.$q.dialog({
                        title: 'Вы уверены?',
                        message: `Безвозвратно будут удалены следующие боты: ${_vm.selected
                          .map(v => v._id)
                          .join(',')}`,
                        cancel: true,
                        persistent: true
                      }).onOk(() => {
                        mutate();
                      })}}})]}}],null,true)})],2),(_vm.$q.screen.lt.md)?_c('q-input',{staticClass:"full-width q-pt-md",style:(_vm.$q.screen.lt.md ? 'min-width: 330px' : 'max-width: 400px;'),attrs:{"rounded":"","color":"primary","outlined":"","dense":"","label":"Поиск"},model:{value:(_vm.searchConditionText),callback:function ($$v) {_vm.searchConditionText=$$v},expression:"searchConditionText"}}):_vm._e()]},proxy:true}],null,true)})]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }