
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import { PaginationFields } from "@/graphql/admin-fragments.ts";
import gql from "graphql-tag";

interface TableColumn {
  [key: string]: any;
  enum?: Array<any>;
  editable?: boolean;
  type: "number" | "string" | "boolean" | "json";
}

@Component({
  components: {
    CircleLoading
  }
})
export default class AdminSteamBot extends Vue {
  @Prop({}) title: any;
  @Prop({ required: true }) modelName: any;
  @Prop({ required: true }) columns: TableColumn[] | any;
  JSON = JSON;

  page = 1;
  loading = false;
  selected: any[] = [];
  docs: any[] = [];
  rowsNumber = 1;
  rowsPerPage = 1;
  searchConditionText: any = "";
  PaginationFields = PaginationFields;
  pageHeight = 0;

  @Watch("selected")
  selectedWatcher(value) {
    this.$emit("update:selected", value);
  }

  styleFn(offset, height) {
    this.pageHeight = height - offset;

    return {
      height: height - offset + "px",
      "overflow-x": "hidden"
    };
  }

  deselectAll() {
    this.selected = [];
  }

  async updateMutate({ variables = {} }) {
    this.loading = true;
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation updateModelDoc(
            $_id: ID!
            $updatedFields: JSON!
            $unsetFields: JSON
            $modelName: String!
          ) {
            updateModelDoc(
              _id: $_id
              updatedFields: $updatedFields
              modelName: $modelName
              unsetFields: $unsetFields
            )
          }
        `,
        variables: { ...variables }
      });
    } catch (e) {
      this.$q.notify({
        message: e.message,
        multiLine: true,
        type: "negative"
      });
    }
    this.loading = false;
  }

  search(condition) {
    this.searchConditionText = condition ? JSON.stringify(condition) : "";
  }

  get searchCondition() {
    if (this.searchConditionText.indexOf("{") === 0) {
      let condition;
      try {
        eval(`condition=${this.searchConditionText}`);
      } catch {}
      return condition ? condition : {};
    }
    return this.searchConditionText ? { _id: this.searchConditionText } : {};
  }

  get pagesNumber() {
    return Math.ceil(this.rowsNumber / this.rowsPerPage);
  }

  onQueryResult({ data }) {
    if (data && data.getModelDocs) {
      if (data.getModelDocs.page > data.getModelDocs.totalPages) {
        this.page = data.getModelDocs.totalPages;
      }
      this.rowsPerPage = data.getModelDocs.limit;
      this.rowsNumber = data.getModelDocs.totalDocs;
      this.docs = data.getModelDocs.docs.slice();
    }
  }
}
